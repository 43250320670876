.layout {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #f8f8f8;
  padding: 10px;

  & > .content {
    position: relative;
    padding-top: 10px;
    height: calc(100% - 50px);
    display: flex;

    & > .children {
      height: 100%;
      flex: 1;
      overflow: auto;
      background-color: #fff;
    }
  }
  .slide-menu {
    height: 100%;
  }
}
