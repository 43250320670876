@import 'src/styles/variable.scss';
.access-container {
  display: flex;

  .role-list {
    width: 160px;
    border-right: 1px solid #f8f8f8;
    padding-top: 4px;
    .role-item {
      padding: 9px 20px;
      margin-bottom: 8px;
      cursor: pointer;
      position: relative;
      &:hover {
        color: $app-color-primary;
      }

      &.on {
        background-color: rgba($color: $app-color-primary, $alpha: 0.1);
        color: $app-color-primary;

        &::after {
          content: '';
          position: absolute;
          right: 0;
          top: 0;
          width: 3px;
          height: 100%;
          background-color: rgba($color: $app-color-primary, $alpha: 0.7);
        }
      }
    }
  }

  .role-option {
    flex: 1;
    padding: 20px;
  }
}
