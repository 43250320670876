.layout-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;

  .menu-block {
    display: flex;
    align-items: center;

    .slide-btn {
      padding: 14px;
    }
  }
}
