.route-item {
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0 0 5px 0 #e9e9e9;

  .route-header {
    display: flex;
    justify-content: space-between;

    &.border {
      border-bottom: 1px solid #eee;
      padding-bottom: 10px;
      margin-bottom: 10px;
    }
  }

  .route-child-2-list {
    display: flex;
    padding: 6px 0;

    .label {
      flex: 0 0 200px;
    }
  }
}
