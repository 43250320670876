.containers {
  display: flex;
  height: 100%;
  &>.left {
    // width: 200px;
  }
  &>.line {
    width: 10px;
    flex: 0 0 10px;
    height: 100%;
    background-color: #f8f8f8;
  }

  &>.right {
    padding: 10px;
    flex: 1;
  }
}
