.login-page-container {
  width: 100%;
  height: 100%;
  // min-height: 700px;
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  user-select: none;

  .logo-block {
    display: flex;
    align-items: center;
    color: #fff;
    justify-content: center;
    margin-top: 40px;

    img {
      width: 100px;
    }

    .h1 {
      font-size: 30px;
      margin-right: 7px;
      margin-left: 7px;
      font-weight: bold;
    }
    .h2 {
      font-size: 18px;
    }
  }

  .container-bg {
    width: 100%;
    overflow: hidden;

    img {
      width: 100%;
      // filter: blur(10px);
    }
  }
  .title-block {
    text-align: center;
    color: #fff;
    font-size: 30px;
    margin-top: 16px;
  }
  .form-block {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 330px;
    height: 370px;

    form {
      position: absolute;
      top: 113px;
      left: 36px;
      width: 260px;
    }
    .bg {
      position: relative;

      img {
        width: 100%;
        position: absolute;
      }
      .img-2 {
        opacity: 0.5;
        transform: scale(1.13) rotateZ(30deg);
      }
      .img-3 {
        opacity: 0.3;
        transform: scale(1.3);
      }
      .img-4 {
        opacity: 0.1;
        transform: scale(1.5) rotateZ(30deg);
      }
    }

    .submit {
      width: 100%;
    }
  }

  .footer {
    position: absolute;
    bottom: 50px;
    left: 0;
    width: 100%;
    text-align: center;
    color: #fff;
  }
}
